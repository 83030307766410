import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import ForWho from './pages/ForWho';
import CustomCursor from './components/CustomCursor';
import ScrollTop from './components/ScrollTop';
import './App.css';

const App = () => {

  window.onload = function() {
      window.scrollTo(0, 0);
    };

    const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsRotated(true);
      } else {
        setIsRotated(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return (
    <Router>
      <ScrollTop />
      <div className="App">
        <nav className="navigation">
        <Link to="/">
          <svg className="logo" viewBox="0 0 71 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.57827 13.1356C2.27319 13.1356 0.883359 11.4915 0.713867 9.20339H2.88336C3.0359 10.4237 3.52743 11.4407 5.57827 11.4407C6.91725 11.4407 7.86641 10.6949 7.86641 9.57627C7.86641 8.45763 7.30709 8.0339 5.34098 7.71186C2.44268 7.32203 1.0698 6.44068 1.0698 4.16949C1.0698 2.18644 2.76471 0.694915 5.29014 0.694915C7.90031 0.694915 9.45963 1.88136 9.69692 4.20339H7.62912C7.40878 2.9322 6.67997 2.38983 5.29014 2.38983C3.91726 2.38983 3.20539 3.05085 3.20539 3.98305C3.20539 4.9661 3.61217 5.45763 5.71387 5.76271C8.56132 6.15254 10.0359 6.9661 10.0359 9.38983C10.0359 11.4915 8.25624 13.1356 5.57827 13.1356Z" fill="black"/>
            <path d="M15.1675 11.5424C16.6929 11.5424 17.6082 10.4068 17.6082 8.59322V8.47458C17.6082 6.64407 16.6929 5.52542 15.1675 5.52542C13.6421 5.52542 12.7099 6.62712 12.7099 8.45763V8.59322C12.7099 10.4068 13.6082 11.5424 15.1675 11.5424ZM15.1506 13.1356C12.5404 13.1356 10.6082 11.322 10.6082 8.62712V8.49153C10.6082 5.74576 12.5573 3.91525 15.1675 3.91525C17.7607 3.91525 19.7099 5.72881 19.7099 8.44068V8.57627C19.7099 11.339 17.7607 13.1356 15.1506 13.1356Z" fill="black"/>
            <path d="M20.7155 12.9661V4.1017H22.7663V5.45763C23.19 4.62712 24.1561 3.91525 25.4612 3.91525C26.6138 3.91525 27.529 4.40678 27.9697 5.50848C28.6476 4.40678 29.868 3.91525 30.9697 3.91525C32.5968 3.91525 33.9188 4.91525 33.9188 7.35593V12.9661H31.868V7.49153C31.868 6.18644 31.3087 5.62712 30.2917 5.62712C29.2748 5.62712 28.3426 6.30509 28.3426 7.66102V12.9661H26.2917V7.49153C26.2917 6.18644 25.7154 5.62712 24.7155 5.62712C23.6985 5.62712 22.7663 6.30509 22.7663 7.66102V12.9661H20.7155Z" fill="black"/>
            <path d="M39.3251 13.1356C36.7149 13.1356 34.8336 11.4237 34.8336 8.62712V8.49153C34.8336 5.74576 36.7149 3.91525 39.2403 3.91525C41.4437 3.91525 43.4268 5.22034 43.4268 8.38983V8.98305H36.9353C37.0031 10.661 37.8505 11.6102 39.3759 11.6102C40.6132 11.6102 41.2573 11.1186 41.4098 10.2542H43.3929C43.1047 12.1186 41.5963 13.1356 39.3251 13.1356ZM36.9692 7.61017H41.3929C41.2912 6.08475 40.5115 5.40678 39.2403 5.40678C38.0031 5.40678 37.1725 6.23729 36.9692 7.61017Z" fill="black"/>
            <path d="M47.7332 13.1356C45.5128 13.1356 43.8349 11.5593 43.8349 8.67797V8.54237C43.8349 5.71186 45.5128 3.91525 47.8179 3.91525C49.2586 3.91525 50.1569 4.57627 50.6484 5.45763V0H52.6993V12.9661H50.6484V11.4746C50.1739 12.3559 49.0383 13.1356 47.7332 13.1356ZM48.2417 11.5085C49.6654 11.5085 50.7162 10.5593 50.7162 8.57627V8.44068C50.7162 6.49153 49.8179 5.52542 48.3264 5.52542C46.8179 5.52542 45.9366 6.57627 45.9366 8.49153V8.62712C45.9366 10.5763 46.9196 11.5085 48.2417 11.5085Z" fill="black"/>
            <path d="M56.6114 13.1356C54.9842 13.1356 53.6453 12.3559 53.6453 10.5085C53.6453 8.44068 55.6453 7.69492 58.1876 7.69492H59.2893V7.23729C59.2893 6.05085 58.8825 5.45763 57.6792 5.45763C56.6114 5.45763 56.0859 5.9661 55.9842 6.84746H54.0012C54.1707 4.77966 55.8148 3.91525 57.7978 3.91525C59.7809 3.91525 61.3402 4.72881 61.3402 7.15254V12.9661H59.3232V11.8814C58.747 12.6441 57.9673 13.1356 56.6114 13.1356ZM57.1029 11.661C58.3571 11.661 59.2893 11 59.2893 9.86441V8.98305H58.2385C56.6792 8.98305 55.6792 9.33898 55.6792 10.4237C55.6792 11.1695 56.0859 11.661 57.1029 11.661Z" fill="black"/>
            <path d="M63.5781 16L65.1883 12.0678L61.6798 4.1017H63.9002L66.2561 9.81356L68.4256 4.1017H70.4256L65.5951 16H63.5781Z" fill="black"/>
          </svg>
          </Link>

        <div className="nav-right-wrapper">
          <div className={`scroll-arrow ${isRotated ? 'rotated' : ''}`} onClick={scrollToTop}>
            <svg width="16" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20711 14.5858L8.20711 0H10.2071L10.2071 14.5858L16.5 8.29289L17.9142 9.70711L9.20711 18.4142L0.5 9.70711L1.91421 8.29289L8.20711 14.5858Z" fill="black"/>
            </svg>
          </div>
            <div className="links">
                <Link to="/services">Services</Link>
                <Link to="/about-you">About you</Link>
                <Link to="/about-me">About me</Link>
            </div>
          </div>
        </nav>

        <div className="page-wrapper">
          <Routes>
            <Route
              path="/"
              element={
                <TransitionGroup>
                  <CSSTransition
                    key="home"
                    timeout={800}
                    classNames="fade"
                  >
                    <Home />
                  </CSSTransition>
                </TransitionGroup>
              }
            />
            <Route
              path="/about-me"
              element={
                <TransitionGroup>
                  <CSSTransition
                    key="aboutme"
                    timeout={800}
                    classNames="fade"
                  >
                    <About />
                  </CSSTransition>
                </TransitionGroup>
              }
            />
            <Route
              path="/services"
              element={
                <TransitionGroup>
                  <CSSTransition
                    key="services"
                    timeout={800}
                    classNames="fade"
                  >
                    <Services />
                  </CSSTransition>
                </TransitionGroup>
              }
            />
            <Route
              path="/about-you"
              element={
                <TransitionGroup>
                  <CSSTransition
                    key="aboutyou"
                    timeout={800}
                    classNames="fade"
                  >
                    <ForWho />
                  </CSSTransition>
                </TransitionGroup>
              }
            />
          </Routes>
        </div>
      </div>

      <div className="footer">
        <p className="hide">Someday. 2023.</p>

        <div className="footer-links">
          <div className="footer-first-links">
            <span>Work with me: </span><a href="mailto:malthe@heysomeday.dk" className="medium">malthe@heysomeday.dk</a>
            <a href="https://www.linkedin.com/in/malthemogensen/" className="medium">LinkedIn</a>
          </div>
          <a href="tel:+4550903474" className="phone-link-v medium">
          +45<br />50 <br />90 <br />34 <br />74
          </a>
        </div>
      </div>
      <CustomCursor />
    </Router>
  );
};

export default App;
