import React from 'react';
import Jonas from '../images/jonas.png';
import Henrik from '../images/henrik.png';
import Rasmus from '../images/rasmus.png';

const Testimonials = () => {
  return (
    <div>
      <div className="box yellow-box">
        <div className="box-inner-wrapper yellow-inner-wrapper">
          <div>
            <p className="tiny-label">
              * This work was done while I worked at Signifly.
            </p>
          </div>
          <div className="box-inner-content yellow-inner-content">
            <h2>
              Don’t take my word for it
            </h2>

            <div className="quote-wrapper">
              <div className="quote">
                “We kind of knew what we wanted our story to be. But we struggled with defining the core of it. Malthe went way beyond that in his work. He made us aware how our strategic narrative could and should empower our market position, recruitment and even inform our prioritization in product-development.
              </div>
              <div className="reference">
                <img src={Jonas} alt=""/>
                <div className="reference-p">
                  <p>Jonas Overgaard</p>
                  <p>Founder & CEO</p>
                  <p className="medium">Anyday</p>
                </div>
              </div>
            </div>

            <div className="quote-wrapper">
              <div className="quote">
                “The narrative work with Malthe really helped us sharpen how we communicate our mission and vision as we embark on  the next stage in our journey as a company. It helped make our ambitions tangible across the organisation - from marketing, across recruitment to product, and the new identity and comms had measurable positive impact on brand affinity and revenue. It was certainly (pun intended) not cheap, but it was worth it, and I would work with him again in a heartbeat.
              </div>
              <div className="reference">
                <img src={Henrik} alt=""/>
                <div className="reference-p">
                  <p>Henrik Fabrin</p>
                  <p>Co-Founder & CEO</p>
                  <p className="medium">Certainly</p>
                </div>
              </div>
            </div>

            <div className="quote-wrapper">
              <div className="quote">
                “Malthe actually helped us discover a part of our story that we had not thought about ourselves - making us much better at carving out an attractive market position. Furthermore the work was done in a way that was easy to implement into our teams. An important detail when working with external experts.
              </div>
              <div className="reference">
                <img src={Rasmus} alt=""/>
                <div className="reference-p">
                  <p>Rasmus Bruus Larsen</p>
                  <p>Co-Founder & CEO</p>
                  <p className="medium">Make Influence</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
