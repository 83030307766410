import React from 'react';

const ForWho = () => {
  return (
    <div>
        <div className="hero">
          <div>
            <h1>I work with CEOs and leadership teams in entrepreneurial organizations. </h1>
          </div>
          <div className="intro-wrapper default-text">
            <p className="intro intro-services">
            ‘CEO and leadership teams’ because the strategic narrative needs to be accepted and initiated at the top of a company to be successfully implemented. The other way around sounds good but rarely works in reality.
            <br /><br />
            The ‘entrepreneurial’ part does not always translate directly into the size or stage of a company, but it is often easier to explain in that way.
            <br /><br />
            And because I'm a fan of easy, I've summed up two scenarios underneath as an illustrative example of potential starting points: The Startup and the Scaleup.
            </p>
        </div>
      </div>
      <div className="box black-box">
        <div className="box-inner-wrapper black-inner-wrapper">
          <div>
            <p className="tiny-label full-w">
              Scenarios
            </p>
          </div>
          <div className="box-inner-content black-inner-content">
            <div className="scenario default-text">
              <h2 className="max-w-h2">
                The Startup
              </h2>
              <p>
              ...is a team of 3-5 people. They’ve got an idea, maybe even some solid signals from the market - but not necessarily any real tracktion yet. They are somewhere in between soft and seed-funding and looking to raise a round soon. They are pretty strapped for cash but could really use an extra set of hands - preferably a pair that can pilot a strategic helicopter and give them the long-term overview that the everyday does not allow time for anyone to do.
              <br /><br />
              Their first priority might be a solid investment pitch to secure a longer runway that’ll allow them to breathe and recalibrate before taking the business to the next level.
              <br /><br />
              So we’ll start there. We’ll work on the investment pitch as the first tangible output of the strategic narrative. Dreaming big, starting small - ready to scale fast when the funding is sorted.
              </p>
            </div>
            <div className="white-line"></div>
            <div className="scenario default-text">
              <h2 className="max-w-h2">
                The Scaleup
              </h2>
              <p>
              ...is an organisation of +10 people and a solid stream of revenue. They’ve proved that there is a market for their product and have raised some serious capital to take them to their next level (often a seed or series A investment). Now they are ready to ramp up and grow. The only problem is that growing needs direction and structure to stay effective.
              <br /><br />
              Without a shared story that everyone agrees upon, the company often ends up looking and acting like a hydra where all the heads are in conflict with each other. Some might realize that from the get go and others might have learned it the hard way.
              <br /><br />
              So their first priority is to get everyone on the same page. 
              The process of crafting a strategic narrative is built for that.
              It's a process of 4-6 weeks where the CEO and leadership team defines and aligns their current needs and future dreams in a deck that ensures both internal and external alignment in the next phase of growth. 
              <br /><br />
              During the process we’ll map out what areas we need to zoom in on first: 
              </p>
              <ul>
                <li>
                One company might be experiencing a stagnation in their sales and need an updated sales deck or brand film to attract inbound attention in a new segment. 
                </li>
                <li>
                Another might need to recruit a whole team of talents but can’t seem to catch their attention with the existing job-posts.  
                </li>
                <li>
                A third might need to trim their product and roadmap. 
                </li>
              </ul>
              <p>
                …or have an important keynote next week. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForWho;
