import React, { useState } from 'react';

const Toggle = ({ title, number, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="toggle">
      <div className="toggle-header" onClick={toggleOpen}>
        <div className="toggle-top">
          <span className="toggle-number">{number}</span>
          <span className="toggle-action">{isOpen ? '-' : '+'}</span>
        </div>
        <span className="toggle-title">{title}</span>
      </div>
      {isOpen && <div className="toggle-content">{content}</div>}
    </div>
  );
};

export default Toggle;
