import React from 'react';
import { Link } from 'react-router-dom';

const Process = () => {
  return (
    <div>
      <div className="module-wrapper">
        <p className="tiny-label">
          The process
        </p>

        <div className="module-right">
          <h2>
            Strategy is never better than the output it creates
          </h2>
          <div className="normal up-top default-text">
            <p>
            Therefore we always translate the strategic narrative to one or more of the most essential touch points: making it tangible to test its creative power and ability to adapt to the everyday. It can be everything from a sales pitch, an investment pitch deck, an on-boarding email or even a new website or draft of a brand film. Whatever makes most sense for your organization and situation.
            </p>
          </div>
          <Link to="/services" className="page-link">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0858 9.7072L0.5 9.7072L0.5 7.7072L15.0858 7.7072L8.79289 1.41431L10.2071 9.11284e-05L18.9142 8.7072L10.2071 17.4143L8.79289 16.0001L15.0858 9.7072Z" fill="black"/>
            </svg>
            <span>Go to touchpoints</span>
          </Link>
        </div>


      </div>
    </div>
  );
};

export default Process;
