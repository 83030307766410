import React from 'react';
import { Link } from 'react-router-dom';

const Talk = () => {
  return (
    <div>
    <div className="box black-box">
      <div className="box-inner-wrapper">
        <div>
          <p className="tiny-label">
            Let’s talk
          </p>
        </div>
        <div className="box-inner-content">
          <h2 className="normal">
            Somewhere, Somehow, <span className="bold italic"> Someday</span>
          </h2>
          <p>
          I know you are impossibly busy, so let’s design our first session to suit your schedule. I’ll adapt. 
<br /><br />
I’m up for a walk, run, a bike ride, a workout, a haircut, an online meeting, a lunch or simply an old fashioned cup of coffee.
<br /><br />
At this first informal meeting we’ll dig into your situation. We’ll define an ideal output and draft a process of how to get there together.
          </p>
          </div>
      </div>
    </div>
    </div>
  );
};

export default Talk;
