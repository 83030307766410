import React from 'react';
import Toggle from '../components/Toggle';

const Services = () => {
  return (
    <div>
        <div className="hero">
          <div>
            <h1 className="max-w-640">From a strategic someday to an organisational everyday.</h1>
          </div>
          <div className="intro-wrapper">
            <p className="intro intro-services">
              We start with some work on the strategic narrative. But we always translate the strategic narrative to one or more of the most essential touch points: making it tangible to test its creative power and ability to adapt to the everyday.
            </p>
        </div>
      </div>
      <div className="box yellow-box">
        <div className="box-inner-wrapper yellow-inner-wrapper">
          <div>
            <p className="tiny-label full-w">
              Potential deliverables
            </p>
          </div>
          <div className="box-inner-content yellow-inner-content">
            <h2 className="max-w-h2">
              Touch points powered by the strategic narrative
            </h2>

            <div className="toggle-wrapper two-columns">
              <Toggle number={1} title="The Investment Pitch Deck" content="The competition for investor attention is rapidly increasing. The strategic narrative will take your investment pitch to the next level. It will make it much more coherent and memorable. When we are done you’ll stand out from the crowd. A good first step to a second meeting." />
              <Toggle number={2} title="The Sales Deck" content="When a startup grows up, it becomes increasingly difficult to keep a coherent sales pitch. Building the strategic narrative into the sales pitch trains your teams ability to tell the same story - but in their own way. A solid foundation that ensures consistency across the entire operation, but allows individual flexibility to adapt the pitch to each employee and product. " />
              <Toggle number={3} title="Keynote Presentation" content="Working the strategic narrative into a keynote presentation will transform your slides into a story. This will allow you to capture the attention of the audience and leave them with an entertaining experience - not just a list of information. " />
              <Toggle number={4} title="Talent Attraction " content="The competition for talent is brutal. Luckily we know that salary and extrinsic motivation are secondary factors. The strategic narrative will will force you to put meaning and other intrinsic arguments at the front of your strategy.  A great way to attract (and keep) more relevant talent." />
              <Toggle number={5} title="Product Roadmap and Prioritization" content="Without the proper logic and structure in place the product and its many features can evolve into a monster that looks like a headless chicken; running chaotically in all directions. The strategic narrative works as the logic that will direct your decisions on which product features to prioritise - and even more importantly, kill the darlings that are simply out of scope." />
              <Toggle number={6} title="Brand Film Script" content="A strong way to fixate the strategic narrative internally as well as externally is a brandfilm. This is not the big-cinema-studio kind that you’ll buy at an agency. This is the simple version. The way to tell your story in a way that captivates the audience without breaking the wallet." />
              <Toggle number={7} title="Website Copy" content="The website is often the first point of true contact with your audience. Unfortunately too many websites become either too product oriented and sales-focused or too abstract and irrelevant. The strategic narrative will help you find the right balance between the two and improve the visitors experience by creating a simpler and more coherent structure in the information." />
              <Toggle number={8} title="Launch Campaign Concepts" content="Big ambitions and small budgets requires a lot of out of the box thinking and experimentation to find the recipe that generates growth. The strategic narrative works as the creative straitjacket that ensures a story that is simple, strong and coherent enough to understand but different enough to stand out from the crowd." />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
