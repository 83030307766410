import React from 'react';
import Testimonials from '../components/Testimonials';
import Process from '../components/Process';
import Talk from '../components/Talk';

const Home = () => {
  return (
    <div>
    <div className="hero">
      <div>
        <h1>Someday is a business design studio that helps founders and leadership teams craft strong strategic narratives.</h1>
      </div>
      <div className="intro-wrapper">
        <p className="intro">
          The strategic narrative is the story that aligns and powers everything in your organisation: From fundraising and external market orientation, to internal motivation and even product roadmap prioritisation.
        </p>
        <div className="contact-wrapper">
          <div className="first-links">
            <a href="mailto:malthe@heysomeday.dk">malthe@heysomeday.dk</a>
            <a href="https://www.linkedin.com/in/malthemogensen/">LinkedIn</a>
          </div>
          <a href="tel:+4550903474">+45 50 90 34 74</a>
        </div>
      </div>
    </div>

    <Testimonials />
    <Process />
    <Talk />
    </div>
  );
};

export default Home;
