import React from 'react';
import Malthe from '../images/malthe.png';

const About = () => {
  return (
    <div>
      <div className="hero">
        <div>
          <h1 className="normal wide-h1"><span className="medium">Hi! I’m Malthe. </span> I think that sounds a bit boring. So instead I decided to name my company <span className="medium italic">Someday</span>.</h1>
        </div>

        <div className="intro-wrapper two-columns">
          <p className="intro no-marg">
            I’ve worked with brand strategy, creative business development and leadership communication over the last decade. During the last five years I've moved from a more corporate focus towards the entrepreneurial world.
          </p>
          <p className="intro no-marg">
            Today I specialize in crafting and implementing strategic narratives for scaleups. I always work in concert with founders and their leadership team to ensure alignment and ownership across all functions.
          </p>
        </div>
        <img src={Malthe} alt="" className="malthese"/>
      </div>

      <div className="box yellow-box">
        <div className="box-inner-wrapper yellow-inner-wrapper">
          <div>
            <p className="tiny-label full-w">
              Network makes the dream work
            </p>
          </div>
          <div className="box-inner-content yellow-inner-content">
            <h2 className="max-w-h2">
              I get by with a little help from my friends
            </h2>
            <p className="default-text normal">
              Whenever our ambitions move beyond what I can deliver on my own, I have a rolodex of fantastic friends I know and trust: 
            </p>
            <ul>
              <li>
                Designers and photographers to add a visual universe to the words.
              </li>
              <li>
                Animators and videographers to make them move.
              </li>
              <li>
                Developers who can turn it all into digital products.
              </li>
              <li>
                Even a network of investors to provide constructive feedback.
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div className="someday-logo">
        <span className="char">S</span>
        <span className="char">o</span>
        <span className="char">m</span>
        <span className="char">e</span>
        <span className="char">d</span>
        <span className="char">a</span>
        <span className="char">y</span>
      </div>

      <div className="border-black"></div>
    </div>
  );
};

export default About;
